import React from 'react';
import './OurTeam.scss';
import TeamOrbit from "./TeamOrbit/TeamOrbit";

function OurTeam() {

    return (
        <div className="OurTeam modal-bg">
            <div className="content">
                <div className={'titleKey'}>our team</div>
                <div className={'title'}>
                    <div>Young, Talented</div>
                    <div>and Promising People</div>
                </div>
                <div className={'underTitleText'}>
                    The only way to achieve greatness daily is to work with great people who genuinely enjoy their work and take pride in it. What allows us to guarantee that we are the best choice for your projects is our team of diligent and dedicated top-notch professionals who are all about giving the best service and providing the best solutions for every one of our customers. 
                </div>
                <div className={'title'}>The core team</div>
                <TeamOrbit />
            </div>
        </div>
    );
}

export default OurTeam;