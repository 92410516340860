import React, {createRef, useEffect, useState} from 'react';
import './TeamOrbit.scss';
import TeamMember from "./TeamMember/TeamMember";

import logo from "../../../resources/logo.svg";

import vadim from "../../../resources/our_avatars/vadim.jpg";
import tanya from "../../../resources/our_avatars/tanya.jpg";
import andrey from "../../../resources/our_avatars/andrey.jpg";
import dan from "../../../resources/our_avatars/dan.jpg";

import vika from "../../../resources/our_avatars/vika.jpg";
import alex from "../../../resources/our_avatars/alex.jpg";
import dima from "../../../resources/our_avatars/dima.jpg";
import igor from "../../../resources/our_avatars/igor.jpg";
import akshay from "../../../resources/our_avatars/akshay.jpg";

import vasili from "../../../resources/our_avatars/vasili.jpg";
import anna from "../../../resources/our_avatars/anna.jpg";
import maksim from "../../../resources/our_avatars/maksim.jpg";
import nadya from "../../../resources/our_avatars/nadya.jpg";
import sergey from "../../../resources/our_avatars/sergey.jpg";
import alena from "../../../resources/our_avatars/alena.jpg";

function TeamOrbit() {
    const rootRef = createRef<HTMLDivElement>();
    const [centerPos, setCenterPos] = useState(0);
    const [orbits, setOrbits] = useState([140, 275, 375]);
    const secondsForRound = [20, 33,  40];
    const [imgSize, setImgSize] = useState([55, 73, 100]);

    useEffect(() => {
        const size = Math.min(rootRef.current?.getBoundingClientRect().width || rootRef.current?.getBoundingClientRect().height || 0);
        if(size < 800) {
            setOrbits([0.175 * size, 0.34375 * size, 0.46875 * size]);
            setImgSize([0.06875 * size, 0.09125 * size, 0.125 * size]);
        }
        setCenterPos(size/2);
    }, [])
    
    return (
        <div ref={rootRef} className="TeamOrbit">
            <TeamMember avatar={logo} name={'Tahion.pro team'} noMove noTooltip imgHeight={29}
                        centerPos={centerPos} radius={0} secondsForRound={secondsForRound[0]} startDegree={0} imgSize={imgSize[2]}/>
                        
            <TeamMember avatar={dan} name={'Dan Smirnov'} jobCountry={'CEO - Co-Founder, Israel'}
                        centerPos={centerPos} radius={orbits[0]} secondsForRound={secondsForRound[0]} startDegree={30} imgSize={imgSize[1]}/>
            <TeamMember avatar={tanya} name={'Tatyana Ogarkova'} jobCountry={'CPO, Belarus'}
                        centerPos={centerPos} radius={orbits[0]} secondsForRound={secondsForRound[0]} startDegree={110} imgSize={imgSize[1]}/>
            <TeamMember avatar={vadim} name={'Vadim Ogarkov'} jobCountry={'VP R&D - Co-Founder, Belarus'}
                        centerPos={centerPos} radius={orbits[0]} secondsForRound={secondsForRound[0]} startDegree={200} imgSize={imgSize[1]}/>
            <TeamMember avatar={andrey} name={'Andrey Grishman'} jobCountry={'CTO, Belarus'}
                        centerPos={centerPos} radius={orbits[0]} secondsForRound={secondsForRound[0]} startDegree={280} imgSize={imgSize[1]}/>

            <TeamMember avatar={vika} name={'Victoria Efremova'} jobCountry={'COO, Belarus'} reverseWay
                        centerPos={centerPos} radius={orbits[1]} secondsForRound={secondsForRound[1]} startDegree={20} imgSize={imgSize[0]}/>
            <TeamMember avatar={alex} name={'Aleksander Karaka'} jobCountry={'PM, Belarus'} reverseWay
                        centerPos={centerPos} radius={orbits[1]} secondsForRound={secondsForRound[1]} startDegree={85} imgSize={imgSize[0]}/>
            <TeamMember avatar={dima} name={'Dmitriy Bordachev'} jobCountry={'Sr. Team Leader, Belarus'} reverseWay
                        centerPos={centerPos} radius={orbits[1]} secondsForRound={secondsForRound[1]} startDegree={145} imgSize={imgSize[0]}/>
            <TeamMember avatar={igor} name={'Igor Efremov'} jobCountry={'QA, Belarus'} reverseWay
                        centerPos={centerPos} radius={orbits[1]} secondsForRound={secondsForRound[1]} startDegree={210} imgSize={imgSize[0]}/>
            <TeamMember avatar={akshay} name={'Akshay Patil'} jobCountry={'Managing director, India'} reverseWay
                        centerPos={centerPos} radius={orbits[1]} secondsForRound={secondsForRound[1]} startDegree={290} imgSize={imgSize[0]}/>
                    
            <TeamMember avatar={maksim} name={'Maksim Deryabin'} jobCountry={'Developer, Belarus'} 
                        centerPos={centerPos} radius={orbits[2]} secondsForRound={secondsForRound[2]} startDegree={0} imgSize={imgSize[0]}/>
            <TeamMember avatar={nadya} name={'Nadezhda Podkovyrova'} jobCountry={'Developer, Belarus'}
                        centerPos={centerPos} radius={orbits[2]} secondsForRound={secondsForRound[2]} startDegree={90} imgSize={imgSize[0]}/>
            <TeamMember avatar={alena} name={'Aliona Pashkevich'} jobCountry={'Developer, Belarus'}
                        centerPos={centerPos} radius={orbits[2]} secondsForRound={secondsForRound[2]} startDegree={180} imgSize={imgSize[0]}/>
            <TeamMember avatar={vasili} name={'Vasili Khapankou'} jobCountry={'Developer, Belarus'}
                        centerPos={centerPos} radius={orbits[2]} secondsForRound={secondsForRound[2]} startDegree={270} imgSize={imgSize[0]}/>
        </div>
    );
}

export default TeamOrbit;