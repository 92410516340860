import React from 'react';
import { Parallax } from 'react-scroll-parallax';

import "./Preview.scss";

import shadowType1 from "../../resources/comics/shadow-type-1.svg";
import shadowType2 from "../../resources/comics/shadow-type-2.svg";
import shadowType3 from "../../resources/comics/shadow-type-3.svg";

interface IPreviewProps {
    id: number,
    preview: any,
    shadowType: number,
    foreground?: {
        img: any,
        startY: number,
        endY: number,
        startX: number,
        endX: number
    } [],
    shadowCoordinates: any,
    bgCoordinates: any,
    onElementClick?: Function,
}

function Preview(props: IPreviewProps) {
    const onClick = (e: any) => {
        if(props.onElementClick) {
            const id = e.target.closest('.preview').getAttribute('data-id');
            if(typeof(id) !== "undefined" && id !== null){
                props.onElementClick(id);
            }
        }
    }

    return(
            <div
                onClick={onClick}
                className={`preview preview-${props.id} type-${props.shadowType}`}
                data-id={props.id}>
                <div className="preview__foreground">
                    {
                        props.foreground && props.foreground.map((item, index) => {
                          return <Parallax  key={index}
                                            translateX={[item.startX, item.endX]}
                                            translateY={[item.startY, item.endY]}
                                            style={{position: 'absolute', zIndex: 3}}>
                                    <img
                                        key={index}
                                        src={item.img}
                                        alt={'foreground-element'}/>
                                </Parallax>
                        })
                    }
                </div>
                <Parallax
                    translateY={[props.bgCoordinates.start, props.bgCoordinates.end]}
                    style={{position: 'relative', zIndex: 2}}>
                    <div className="preview__inner">
                        <img src={ props.preview } alt={'Comic preview'} />
                    </div>
                </Parallax>
                <Parallax
                    translateY={[props.shadowCoordinates.start, props.shadowCoordinates.end]}
                    translateX={[0, props.id % 2 === 0 ? -10 : 10]}
                    style={{ position: 'absolute', top: 0}}>
                    <div className="preview__shadow">
                        {
                            props.shadowType === 1 && <img src={shadowType1} alt="shadow"/>
                        }
                        {
                            props.shadowType === 2 && <img src={shadowType2} alt="shadow"/>
                        }
                        {
                            props.shadowType === 3 && <img src={shadowType3} alt="shadow"/>
                        }
                    </div>
                </Parallax>
            </div>
    );
}

export default Preview;