import React from 'react';
import './FirstScreen.scss';
import AnimatedImage from "./AnimatedImage/AnimatedImage";

function FirstScreen() {
    return (
        <div className="FirstScreen modal-bg">
            <div className="content">
                <div className={'mainText'}>
                    <div id={'turn-key-services'} />
                    <div className={'titleKey'}>turn-key services</div>
                    <div className={'title'}>Custom Software</div>
                    <div className={'title gradient'}>Development Company</div>
                    <div className={'aboutUs'}>We specialize in customized complex software development solutions and offer the comprehensive range of professional services, including business analysis and product design.</div>
                </div>
                <AnimatedImage />
                <div id={'who-do-we-serve'} />
            </div>
        </div>
    );
}

export default FirstScreen;
