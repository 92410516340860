/* PREVIEWS */
import previewCh1 from "../resources/comics/list/chapter1.svg";
import previewCh2 from "../resources/comics/list/chapter2.png";
import previewCh3 from "../resources/comics/list/chapter3.svg";
import previewCh4 from "../resources/comics/list/chapter4.png";
import previewCh5 from "../resources/comics/list/chapter5.svg";
import previewCh6 from "../resources/comics/list/chapter6.png";
import previewCh7 from "../resources/comics/list/chapter7.png";

/* COMICS */
import chapter1 from "../resources/comics/chapters/chapter1.jpg";
import chapter2 from "../resources/comics/chapters/chapter2.jpg";
import chapter3 from "../resources/comics/chapters/chapter3.jpg";
import chapter4 from "../resources/comics/chapters/chapter4.jpg";
import chapter5 from "../resources/comics/chapters/chapter5.jpg";
import chapter6 from "../resources/comics/chapters/chapter6.jpg";
import chapter7 from "../resources/comics/chapters/chapter7.jpg";

/*  FOREGROUND ELEMENTS */
import ch2Vika from "../resources/comics/list/foreground/VikaCh2.png";
import ch3Aliens from "../resources/comics/list/foreground/AliensCh3.svg";
import ch3Meerkats from "../resources/comics/list/foreground/MeerkatsCh3.svg";
import ch4LittleDragon from "../resources/comics/list/foreground/LittleDragonCh4.svg";
import ch4Dragon from "../resources/comics/list/foreground/DragonCh4.svg";
import ch5Bee from "../resources/comics/list/foreground/BeeCh5.svg";
import ch6Gulls from "../resources/comics/list/foreground/GullsCh6.svg";
import ch7Alien from "../resources/comics/list/foreground/AlienCh7.svg";
import ch7Meerkat from "../resources/comics/list/foreground/MeerkatCh7.svg";

const comics = [
    {
        id: 0,
        name: "Being an ITS superhero",
        preview: previewCh1,
        comic: chapter1,
        toolTipColor: '#CC417D',
        shadowType: 1,
        foreground: [],
        bgCoordinates: {
            start: -20,
            end: 30
        },
        shadowCoordinates:{
            start: -30,
            end: 40
        },
    },
    {
        id: 1,
        name: "Grave rider\n" +
            "Deryakulas revival",
        preview: previewCh2,
        comic: chapter2,
        toolTipColor: '#6C4AA3',
        shadowType: 2,
        foreground: [
            {
                img: ch2Vika,
                startY: 90,
                endY: 60,
                startX: 0,
                endX: -10
            }
        ],
        bgCoordinates: {
            start: 10,
            end: -20
        },
        shadowCoordinates:{
            start: 0,
            end: 10
        }
    },
    {
        id: 2,
        name: "Close Encounters of the Fifth Kind",
        preview: previewCh3,
        comic: chapter3,
        toolTipColor: '#E97E00',
        shadowType: 3,
        foreground: [
            {
                img: ch3Aliens,
                startY: 15,
                endY: 45,
                startX: 220,
                endX: 190,
            },
            {
                img: ch3Meerkats,
                startY: 210,
                endY: 200,
                startX: 170,
                endX: 145
            }
        ],
        bgCoordinates: {
            start: 0,
            end: -20
        },
        shadowCoordinates:{
            start: -5,
            end: 0
        }
    },
    {
        id: 3,
        name: "Dragon Riders",
        preview: previewCh4,
        shadowType: 2,
        comic: chapter4,
        toolTipColor: '#E5735A',
        foreground: [
            {
                img: ch4LittleDragon,
                startY: 250,
                endY: 130,
                startX: 65,
                endX: 75,
            },
            {
                img: ch4Dragon,
                startY: 10,
                endY: -55,
                startX: 103,
                endX: 105,
            }
        ],
        bgCoordinates: {
            start: 10,
            end: 0
        },
        shadowCoordinates:{
            start: 0,
            end: 10
        }
    },
    {
        id: 4,
        name: "Buzz Off",
        preview: previewCh5,
        comic: chapter5,
        toolTipColor: '#E97E00',
        shadowType: 1,
        foreground: [
            {
                img: ch5Bee,
                startY: 10,
                endY: -10,
                startX: 145,
                endX: 160
            }
        ],
        bgCoordinates: {
            start: 10,
            end: 0
        },
        shadowCoordinates:{
            start: 30,
            end: 5
        },
    },
    {
        id: 5,
        name: "The Anomaly",
        preview: previewCh6,
        comic: chapter6,
        toolTipColor: '#CC417D',
        shadowType: 2,
        foreground: [
            {
                img: ch6Gulls,
                startY: 15,
                endY: 0,
                startX: 20,
                endX: 5
            }
        ],
        bgCoordinates: {
            start: 10,
            end: 0
        },
        shadowCoordinates:{
            start: 10,
            end: -5
        },
    },
    {
        id: 6,
        name: "We Come In Peace",
        preview: previewCh7,
        comic: chapter7,
        toolTipColor: '#6C4AA3',
        shadowType: 3,
        foreground: [
            {
                img: ch7Meerkat,
                startY: 320,
                endY: 320,
                startX: 540,
                endX: 500
            },
            {
                img: ch7Alien,
                startY: 0,
                endY: -15,
                startX: 30,
                endX: 30
            },
        ],
        bgCoordinates: {
            start: -7,
            end: 0
        },
        shadowCoordinates:{
            start: 15,
            end: -5
        },
    },
];

export default comics;