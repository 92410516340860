import React, { useEffect, useState, useRef, useLayoutEffect  } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";

import comicsData from "./ComicsData";

import RocketImg from "../resources/comics/rocket.webp";
import arrow from '../resources/comics/arrow.svg';

gsap.registerPlugin(ScrollTrigger, MotionPathPlugin);

function Rocket() {
    const [toolTip, setToolTip] = useState<{number: number, name: string, color: string}>({
        number: 1,
        name: 'Being an ITS superhero',
        color: '#CC417D'
    });

    const [currentIndex, setCurrentIndex] = useState<Number>(-1);

    const rocket = useRef(null);
    const rocketPath = useRef(null);

    const tl = useRef(gsap.timeline({
        defaults: {
            duration: 1,
            ease: "none",
        },
    }));

    const searchPreview = () =>{
            for(let item of Array.from(document.querySelectorAll('.preview'))) {
                console.log(isElementXPercentInViewport(item), item.classList)
                if(!isElementXPercentInViewport(item))
                    continue
                let itemHtmlElement = item as HTMLElement;
                let index = itemHtmlElement.dataset.id
                if (index) {
                    if (Number(index) % 2 === 0) {
                        setCurrentIndex(Number(-1));
                    } else {
                        setCurrentIndex(Number(1));
                    }
                }
                setToolTip({
                    number:  Number(index) + 1,
                    name: String(comicsData[Number(index)]!.name),
                    color: String(comicsData[Number(index)]!.toolTipColor)
                })
                break;
            }
    }

    const hideTooltip = () => {
        if(window.scrollY > 0)
            gsap.to('#arrow, #tooltip',{
                opacity: 0,
                duration: .1,
            })
    }

    useLayoutEffect(() => {
        gsap.set('#rocket, #arrow, #tooltip', {
            transformOrigin: "center",
            xPercent: -50,
            yPercent: -50
        })

        ScrollTrigger.addEventListener("scrollEnd", searchPreview);
        tl.current
            .to('#rocket-path',{
                duration: 0,
                scaleY: window.innerWidth < 2600 ? 0.5 : 1,
                scaleX: window.innerWidth < 2600 ? 0.6 : 1,
            })
            .to('.path-wrapper circle',{
                duration: 0,
                scale: window.innerWidth < 2600 ? 0.5 : 1
            })

        return () => {
            ScrollTrigger.removeEventListener('scrollEnd', searchPreview);
        }
    }, [])

    useEffect(() => {
        tl.current
            .to('#rocket',{
                opacity: 1,
                duration: 1.1,
                delay: 2.5,
                motionPath: {
                    path: rocketPath.current!,
                    align: rocketPath.current!,
                    alignOrigin: [0.5, 0.5],
                    autoRotate: true,
                    start: 0,
                    end: 0.08,
                }
            })
            .to('#arrow, #tooltip', {
                duration: 1,
                opacity: 1,
            })

        setTimeout(()=> {
            gsap.to('#rocket', {
                    ease: "none",
                    motionPath: {
                        start: 0,
                        path: rocketPath.current!,
                        align: rocketPath.current!,
                        alignOrigin: [0.5, 0.5],
                        autoRotate: true,
                    },
                    scrollTrigger: {
                        start: "top 65%",
                        end: "bottom -7%%",
                        scrub: 1.5,
                        onScrubComplete: () =>{
                            gsap.to('#tooltip, #arrow',{
                                opacity: 1,
                                duration: .3,
                            })
                        },
                        onUpdate: () =>{
                            hideTooltip()
                        }
                    },
                })
        }, 1100)
    }, [])

    useEffect(() =>{
           if(window.innerWidth >= 2600){
                gsap.to('#arrow', {
                    ease: "none",
                    scrollTrigger: {
                        start: "top 55%",
                            end: "bottom -7%%",
                            scrub: 0,
                    },
                    motionPath: {
                        path: rocketPath.current!,
                        align: rocketPath.current!,
                            offsetX: Number(currentIndex) === -1 ? -230 : 230,
                            autoRotate: Number(currentIndex) === -1 ? -90 : 90,
                    },
                })
               gsap.to('#tooltip', {
                    ease: "none",
                    scrollTrigger: {
                        start: "top 55%",
                       end: "bottom -7%%",
                       scrub: 0,
                    },
                    motionPath: {
                        path: rocketPath.current!,
                        align: rocketPath.current!,
                       offsetX: Number(currentIndex) === -1 ? 400 : -300,
                       autoRotate: false
                    },
                })
           } else {
                gsap.to('#arrow', {
                    ease: "none",
                    scrollTrigger: {
                        start: "top 60%",
                        end: "bottom -7%%",
                        scrub: 0
                    },
                    motionPath: {
                        path: rocketPath.current!,
                        align: rocketPath.current!,
                        offsetX: Number(currentIndex) === -1 ? -160 : 160,
                        autoRotate: Number(currentIndex) === -1 ? -90 : 90,
                    },
                })
                gsap.to('#tooltip', {
                   ease: "none",
                   scrollTrigger: {
                       start: "top 55%",
                       end: "bottom -7%%",
                       scrub: 0,
                   },
                   motionPath: {
                       path: rocketPath.current!,
                       align: rocketPath.current!,
                       offsetX: Number(currentIndex) === -1 ? 310 : -310,
                       autoRotate: false
                   },
                })
            }
        },[currentIndex, toolTip])

    const isElementXPercentInViewport = function(el: Element) {
        let
            rect = el.getBoundingClientRect(),
            windowHeight = (window.innerHeight || document.documentElement.clientHeight);

        return !(
            Math.floor(100 - (((rect.top >= 0 ? 0 : rect.top) / -rect.height) * 100)) < 50 ||
            Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < 50
        )
    };

    return(
        <div className={'path-wrapper'}>
            <svg width="1330" height="11811" viewBox="0 0 1330 11811"  fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id="rocket-path" ref={rocketPath} d="M409.553 -17.1581C692.023 129.157 1263.06 571.76 1287.44 1171.65C1317.93 1921.51 568.157 1923.55 231.594 2325.76C-104.968 2727.98 -5.83694 3195.43 299.743 3585.66C605.322 3975.89 1248.84 4051.78 1333.99 4740.07C1402.11 5290.7 755.226 5493.53 425.732 5832.82C96.2387 6172.12 7.45349 6483.14 73.4756 6939.56C156.003 7510.07 865.403 7743.32 1121.22 8026.77C1377.03 8310.22 1424.96 8633.76 1218.26 8978.27C1011.55 9322.78 462.641 9625.42 253.634 10071.7C44.6272 10518 132.764 10879.6 546.651 11194.6C960.539 11509.6 1071.24 11747.8 1071.24 11747.8C1121.06 11882.4 1234.25 12150.6 1179.16 12606.3C1124.08 13062.1 282.42 13449.3 330.246 13873.7C368.507 14213.3 605.247 14477.6 718.835 14567.2" stroke="white" strokeWidth="2" strokeDasharray="8 8"/>

                <g className={'dots'}>
                    <circle cx="745.384" cy="416.863" r="20" fill="white"/>
                    <circle cx="75.375" cy="1273.35" r="20" fill="white"/>
                    <circle cx="635.52" cy="2080.61" r="20" fill="white"/>
                    <circle cx="230.8418" cy="2956.04" r="20" fill="white"/>
                    <circle cx="337.503" cy="3795.13" r="20" fill="white"/>
                    <circle cx="690.69" cy="4555.64" r="20" fill="white"/>
                    <circle cx="220.42" cy="5500.33" r="20" fill="white"/>
                    <circle cx="710.697" cy="6000.4" r="20" fill="white"/>
                </g>

                <g className={'dots-large'}>
                    <circle cx="959.384" cy="416.863" r="20" fill="white"/>
                    <circle cx="398.375" cy="2173.35" r="20" fill="white"/>
                    <circle cx="20.8418" cy="2956.04" r="20" fill="white"/>
                    <circle cx="337.503" cy="3636.13" r="20" fill="white"/>
                    <circle cx="1027.69" cy="4154.64" r="20" fill="white"/>
                    <circle cx="1327.42" cy="4922.33" r="20" fill="white"/>
                    <circle cx="787.697" cy="5547.4" r="20" fill="white"/>
                    <circle cx="1033.52" cy="1780.61" r="20" fill="white"/>
                    <circle cx="198.157" cy="6119.05" r="20" fill="white"/>
                    <circle cx="68.5178" cy="6937.55" r="20" fill="white"/>
                    <circle cx="508.387" cy="7570.5" r="20" fill="white"/>
                    <circle cx="1182" cy="8097.13" r="20" fill="white"/>
                    <circle cx="1233.3" cy="8956.19" r="20" fill="white"/>
                    <circle cx="615.216" cy="9602.83" r="20" fill="white"/>
                    <circle cx="205.599" cy="10169.7" r="20" fill="white"/>
                    <circle cx="378.557" cy="11038.4" r="20" fill="white"/>
                    <circle cx="1069.73" cy="11734.1" r="20" fill="white"/>
                </g>

                <foreignObject x="0" y="0" width="300" height="250" id={'tooltip'}>
                    <div className={'rocket-text'} style={{backgroundColor: toolTip.color}}>
                        <p className={'rocket-chapter'}>
                            Chapter {toolTip.number}
                        </p>
                        <p className={'rocket-name'}>
                            {toolTip.name ? toolTip.name : '---'}
                        </p>
                    </div>
                </foreignObject>
                <image href={RocketImg} ref={rocket} id={'rocket'} opacity={0} className={'rocket'} />
                <image xlinkHref={arrow} className={'rocket-arrow'} id={'arrow'} />
            </svg>
        </div>
    );
}

export default Rocket;