import React, {useState, useEffect} from 'react';

import ImageZoom from "./Zoom";

import Modal from "../../helpers/Modal/Modal";

import "./ComicModal.scss";

interface IModalProps {
    isOpen: boolean,
    closeModal: Function,
    chapter: any,
}

function ComicModal(props: IModalProps) {
    const [localOpen, setLocalOpen] = useState(props.isOpen);
    const [imgDimensions, setImgDimensions] = useState({
        width: 0,
        height: 0
    });
    const [koeffs, setKoeffs] = useState({
        l2560: 4.7,
        l1920: 2.95,
        m1440: 2.15
    })

    const getDimensions = () =>{
        let img = new Image();
        img.src = props.chapter;
        img.onload = () =>{
            let newHeight = 0;
            if(window.innerWidth > 1200){
                newHeight = img.width / (window.innerWidth / koeffs.m1440) * 100
            }
            if(window.innerWidth > 1500){
                newHeight = img.width / (window.innerWidth / koeffs.l1920) * 100
            }
            if(window.innerWidth > 2000) {
                newHeight = img.width / (window.innerWidth / koeffs.l2560) * 100
            }
            setImgDimensions({
                width:   img.width,
                height:  newHeight
            })
        }
    }

    useEffect(() => {
        if(props.isOpen){
            setLocalOpen(true);
        }

        getDimensions()

    }, [props.isOpen])

    const closeModal = () => {
        setLocalOpen(false);
    }

    useEffect(() => {
        if(localOpen){
            document.body.classList.remove('modal-inactive');
            document.body.classList.add('comic-modal-active');
        }
        else{
            const needAdd = document.body.classList.contains('comic-modal-active');
            document.body.classList.remove('comic-modal-active');
            if(needAdd) {
                document.body.classList.add('modal-inactive');
                setTimeout(() => {
                    props.closeModal();
                }, 500);
            }
        }
    }, [localOpen])

    if(!props.chapter) return <></>;
    return (
        <Modal
            isOpen={props.isOpen}
            closeModal={closeModal}
        >
            <svg
                onClick={closeModal}
                className={'chapter-close'} width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M28.7602 30.6416L5.56054 7.44196C4.97844 6.85986 4.98119 5.91226 5.56667 5.32677C6.15216 4.74129 7.09976 4.73854 7.68186 5.32064L30.8815 28.5203C31.4636 29.1024 31.4609 30.05 30.8754 30.6355C30.2899 31.221 29.3423 31.2237 28.7602 30.6416Z" fill="#263238"/>
                <path d="M30.8815 7.47311L7.68187 30.6727C7.09977 31.2549 6.15217 31.2521 5.56668 30.6666C4.98119 30.0811 4.97845 29.1335 5.56055 28.5514L28.7602 5.35179C29.3423 4.76968 30.2899 4.77243 30.8754 5.35792C31.4609 5.9434 31.4636 6.891 30.8815 7.47311Z" fill="#263238"/>
            </svg>
           <div className={'chapter'}>
               {
                   imgDimensions.width > 0 &&
                    <ImageZoom
                       src={props.chapter}
                       zoom={"250"}
                       height={ String(imgDimensions.height) }
                    />
               }
           </div>
        </Modal>
    );
}

export default ComicModal;