import React, { useState, useEffect } from "react";

interface ZoomProps {
    src: string,
    zoom?: string,
    alt?: string,
    width?: string,
    height?: string,
}

function ImageZoom(props: ZoomProps) {
    const [zoomed, setZoomed] = useState("1");
    const [position, setPosition] = useState("50% 50%");
    const [imgData, setImgData] = useState("");
    const [imgDataBg, setImgDataBg] = useState("");

    let figureClass = imgData ? "loaded" : "loading";
    let figureZoomed = zoomed === "0" ? "zoomed" : "fullView";

    function zoomInPosition(e: React.MouseEvent<HTMLElement, MouseEvent>) {
        const zoomer = e.currentTarget.getBoundingClientRect();

        let x, y, offsetX, offsetY;
        offsetX = e.clientX - zoomer.x;
        offsetY = e.clientY - zoomer.y;
        x = (offsetX / zoomer.width) * 100;
        y = (offsetY / zoomer.height) * 100;
        setPosition(`${x}% ${y}% `);
    }

    function zoomInPositionTouch(e: React.TouchEvent<HTMLElement>) {
        const zoomer = e.currentTarget.getBoundingClientRect();
        let x, y, offsetX, offsetY;
        offsetX =  e.touches[0].clientX - zoomer.x;
        offsetY =  e.touches[0].clientY - zoomer.y;
        x = (offsetX / zoomer.width) * 100;
        y = (offsetY / zoomer.height) * 100;
        setPosition(`${x}% ${y}% `);
    }

    function toggleZoomImage(e: React.MouseEvent<HTMLElement, MouseEvent>) {
        if (zoomed === "0") {
            setZoomed("1");
            setImgDataBg("")
           let chapter = document.querySelector('.ReactModal__Content .chapter') as HTMLElement;
            chapter.style.cursor = 'zoom-in'
        } else {
            setZoomed("0");
            setImgDataBg(imgData)
            let chapter = document.querySelector('.ReactModal__Content  .chapter') as HTMLElement;
            chapter.style.cursor = 'zoom-out'
            zoomInPosition(e);
        }
    }

    function handleEnter(e: React.MouseEvent<HTMLElement, MouseEvent>) {
        toggleZoomImage(e);
    }

    function handleMove(e: React.MouseEvent<HTMLElement, MouseEvent>) {
        if (zoomed === "0") {
            zoomInPosition(e);
        }
    }

    function handleMoveTouch(e: React.TouchEvent<HTMLElement>) {
        if (zoomed === "0") {
            zoomInPositionTouch(e);
        }
    }

    useEffect(() => {
        setZoomed("0");
        let img = new Image();
        img.src = props.src;
        img.addEventListener("load", () => {
            setTimeout(() => {
                setZoomed("1");
                setImgData(img.src);
            }, 200);
        });
    }, []);

    return (
        <figure
            className={[figureClass, figureZoomed].join(" ")}
            style={{
                backgroundImage: "url(" + imgDataBg + ")",
                backgroundSize: props.zoom + "%",
                backgroundPosition: position,
            }}
            onClick={(e) => handleEnter(e)}
            onMouseMove={(e) => handleMove(e)}
            onTouchMove={(e) => handleMoveTouch(e)}
        >
            <img
                src={imgData}
                alt={props.alt}
                style={{ opacity: zoomed }}
                width={props.width}
                height={props.height}
            />
        </figure>
    );
}

ImageZoom.defaultProps = {
    zoom: "200",
    alt: "Chapter",
    width: "100%",
    height: "auto",
};

export default ImageZoom;